@import "./variables";

* {
  scroll-behavior: smooth;
  color: beige;
}

html {
  background: linear-gradient(180deg, rgb(30, 29, 29), rgb(11, 9, 14));
  background-repeat: no-repeat;
  min-height: 100vh;

  color-scheme: dark
}

#root {min-width: min-content;}

nav {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  a {
    color: $primary_color;
    text-decoration: none;
    transition: $default_transition;
    width: 120px;
    display: flex;
    justify-content: center;
    &:hover:not(.active) {
      text-decoration: underline 3px orangered;
      text-underline-offset: .5em;
    }
    &.active {
      color: #5d5656;
      letter-spacing: 2px;
      pointer-events: none;
    }
  }
  padding: 1em 0;
}

.breadcrumb {
  display: flex;
  padding: 0;

  & li {
    list-style: none;
    text-transform: capitalize;
    margin: 0 2px;
    a {
      text-decoration: none;
      transition: $default_transition;
    }
    a:hover {
      color: tomato;
    }
    &:not(a) {color: grey;}
    &:not(:first-child):before {content: "/ "}
    }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ::selection {
    background-color: $primary_color;
    color: white;
  }
  > div:not(.project) {
    min-height: 89.2vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
  }
  img, .headerImage {
    border-radius: 1em;
    object-fit: cover;
    height: 100%;
  }
}
.portfolio {
  display: flex;
  flex-direction: column;
}

.para {
  display: flex;
  flex-wrap: wrap;
}

section {
  width: min(1200px, 100% - 2rem);
  &.small {
    width: min(639px, 100% - 2rem);
  }
}
.centerText {
  display: flex;
  justify-content: center;
}

.pointer {
  color: white;
}

footer {
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
  width: 100%;
  min-height: 5vh;
}

footer ul {
  width: min(1200px, 100% - 2rem);
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  li {
    color: rgb(113, 113, 113);
    display: flex;
  }
}

.projects {
  display: flex;
  align-items: center;
  > div {
    display: flex;
    justify-content: center;
    
    a {text-decoration: none;}
  }

  .projectcontainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .projectCard {
    display: flex;
    flex-direction: column;
    transition: $default_transition;
    width: min(550px, 100% - 2em);
    margin-top: 1em;
    
    &:hover {
      transform: scale(102%);
    }
    
    & img {
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      max-width: 100%;
      border-radius: 1em; 
    }

    & a {
      text-decoration: none;
    }
    
    .projectInfo {
      h3, h4 {
        color: beige;
      }
    }
  }
  transition: $default_transition;
}

.certificateCard {
  // @extend .projects, .projectCard;
  display: flex;
  justify-content: center;
  transition: $default_transition;

  &:hover {
    transform: scale(102%);
  }
}

.iconLink, i {
  transition: .2s all ease-in-out;
  :hover {
    color: $primary_color;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
  > h3, h4 {
    margin: 0 0 0 0;
  }
}
.info_row {
  display: flex;
  flex-wrap: wrap;

  .info_text {
    width: min(500px, 70%, 70vh);
  }
}
.center {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 530px) {
  
  // .row > h4:first-of-type {
  //   width: fill;
  //   display: flex;
  //   justify-content: flex-end;
  // }

  
  // .projects .projectCard img {
  //   max-width: 400px;
  // }
  // .projects .projectCard .projectInfo {
  //   flex-direction: column;
  //   h4 {
  //     display: none;
  //   }
  // }
}
