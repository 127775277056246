.achievements {
  width: 100%;
  display: grid;
  row-gap: 20px;
  a {
    text-decoration: none;
  }
}

.certificate {
  max-height: 150px;  
  & img {
    aspect-ratio: 1/1;
    max-width: 150px;
  }
}

.certificate__info {
  display: flex;
  width: 100%;
  transition: .2s ease-in-out;
  &:hover {
    border-radius: 2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0),  rgb(0, 0, 0));
    padding-left: 1em;
  }

  .certificate__href_credit {
    width: 100%;  
    padding-left: 1.5em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      margin-top: 3px;
    }
  }

  .certificate__description {
    width: 100%;  

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: .2em 1em 0 0;
    p {
      margin: 0;
    }
  }
}

.credit__link {
  color: gray;
  width: min-content;
  &:hover {
    text-decoration: underline;
  }
}

@media (width < 500px) {

  .achievements {
    display: grid;
    // grid-area: span 20;
  }
  .certificate {
    height: 200px;
    grid-row: 1/ span 17;
  }
  .certificate__info {
    flex-direction: column;
    align-items: center
  }

  .certificate__href_credit, .certificate__description {
    padding-left: 0!important;
    margin: 0!important;

    text-align: center;
  }


}