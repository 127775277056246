* {
  scroll-behavior: smooth;
  color: beige;
}

html {
  background: linear-gradient(180deg, rgb(30, 29, 29), rgb(11, 9, 14));
  background-repeat: no-repeat;
  min-height: 100vh;
  color-scheme: dark;
}

#root {
  min-width: min-content;
}

nav {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1em 0;
}
nav a {
  color: orangered;
  text-decoration: none;
  transition: 0.2s all ease-in-out;
  width: 120px;
  display: flex;
  justify-content: center;
}
nav a:hover:not(.active) {
  text-decoration: underline 3px orangered;
  text-underline-offset: 0.5em;
}
nav a.active {
  color: #5d5656;
  letter-spacing: 2px;
  pointer-events: none;
}

.breadcrumb {
  display: flex;
  padding: 0;
}
.breadcrumb li {
  list-style: none;
  text-transform: capitalize;
  margin: 0 2px;
}
.breadcrumb li a {
  text-decoration: none;
  transition: 0.2s all ease-in-out;
}
.breadcrumb li a:hover {
  color: tomato;
}
.breadcrumb li:not(a) {
  color: grey;
}
.breadcrumb li:not(:first-child):before {
  content: "/ ";
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.wrapper ::selection {
  background-color: orangered;
  color: white;
}
.wrapper > div:not(.project) {
  min-height: 89.2vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
}
.wrapper img, .wrapper .headerImage {
  border-radius: 1em;
  object-fit: cover;
  height: 100%;
}

.portfolio {
  display: flex;
  flex-direction: column;
}

.para {
  display: flex;
  flex-wrap: wrap;
}

section {
  width: min(1200px, 100% - 2rem);
}
section.small {
  width: min(639px, 100% - 2rem);
}

.centerText {
  display: flex;
  justify-content: center;
}

.pointer {
  color: white;
}

footer {
  display: flex;
  justify-content: center;
  flex: 0 1 auto;
  width: 100%;
  min-height: 5vh;
}

footer ul {
  width: min(1200px, 100% - 2rem);
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}
footer ul li {
  color: rgb(113, 113, 113);
  display: flex;
}

.projects {
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;
}
.projects > div {
  display: flex;
  justify-content: center;
}
.projects > div a {
  text-decoration: none;
}
.projects .projectcontainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.projects .projectCard {
  display: flex;
  flex-direction: column;
  transition: 0.2s all ease-in-out;
  width: min(550px, 100% - 2em);
  margin-top: 1em;
}
.projects .projectCard:hover {
  transform: scale(102%);
}
.projects .projectCard img {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  max-width: 100%;
  border-radius: 1em;
}
.projects .projectCard a {
  text-decoration: none;
}
.projects .projectCard .projectInfo h3, .projects .projectCard .projectInfo h4 {
  color: beige;
}

.certificateCard {
  display: flex;
  justify-content: center;
  transition: 0.2s all ease-in-out;
}
.certificateCard:hover {
  transform: scale(102%);
}

.iconLink, i {
  transition: 0.2s all ease-in-out;
}
.iconLink :hover, i :hover {
  color: orangered;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row > h3, .row h4 {
  margin: 0 0 0 0;
}

.info_row {
  display: flex;
  flex-wrap: wrap;
}
.info_row .info_text {
  width: min(500px, 70%, 70vh);
}

.center {
  display: flex;
  justify-content: center;
}